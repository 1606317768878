var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"47ce083fff8ea0d435cf2a5c693ff93da91cdb5f"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import config from 'config';

const isWidgetPage = window.location.pathname.includes('/widgets-');
const tracesSampleRateValue = isWidgetPage ? 0 : 0.1;
if (config.get('public.sentry.enabled')) {
  Sentry.init({
    integrations: [
      Sentry.browserTracingIntegration({
        enableInp: true,
      }),
    ],
    dsn: config.get('public.sentry.dsn'),
    // Adjust this value in production, or use tracesSampler for greater control
    // https://develop.sentry.dev/sdk/performance/#tracessamplerate
    // https://docs.sentry.io/platforms/javascript/guides/nextjs/configuration/sampling/#setting-a-uniform-sample-rate
    tracesSampleRate: tracesSampleRateValue,
    // Block transactions on widgets page
    denyUrls: [/^(.*?(\*?.fixter.co.uk\/widgets\b)[^$]*)$/, /^(.*?(\*?.fixter.fr\/widgets\b)[^$]*)$/],
    ignoreErrors: [
      'cookieconsent',
      'widget',
      'GraphQL error: getSession',
      '_submitInjector',
      'HTMLScriptElement.t.onload',
      'chunks/pages/_app',
    ],
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
    environment: config.get('public.environment'),
  });
}
